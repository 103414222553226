import "./App.css";
import ActivareChatbot from "./components/activareChatBot";


function App() {

  return <div>        
    {ActivareChatbot()}
    </div>;
}

export default App;
