
import ChatBot from 'react-simple-chatbot';
import React, { useState } from 'react';
import { OpenAI  } from 'openai';
import { ThemeProvider } from 'styled-components';
import { useEffect } from 'react';


// all available props
const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#145783',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#145783',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

// Set some properties of the bot
const config = {
  floating: false,
  botAvatar: '/activatusalud.png', // The bot's avatar
  userAvatar: '/user.png', // The user's avatar
  headerTitle: 'Activare Chatbot', // The title of the chatbot  
  style: { fontFamily: 'Arial', fontSize: '24px'},
  placeholder: 'Escribe aquí...', // The placeholder of the input field
  recognitionEnable: true, // Enable voice recognition
  recognitionLang: 'es-ES', // The language of the voice recognition  
  speechSynthesis: { enable: false, lang: 'es-ES'}

};


const SearchIA = ({steps}) => {

  const { question, search } = steps;
  const synth = window.speechSynthesis;
  

  
  const [state, setState] = useState({
    question,
    search,
    response: ''
  });
  
  useEffect(() => {
    const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true}); 
  const handleEnd2 = async ({ steps }) => {
    // Obtén la última respuesta del usuario
    var arr = Object.values(steps)
    const userMessage = arr[arr.length-2].message
    // Realiza una llamada al API de OpenAI para obtener la respuesta
    try {
      const response = await openai.chat.completions.create({
        model: 'gpt-3.5-turbo', // O el motor que prefieras
        messages: [{ role: "user", content: userMessage }]        
      });
      console.log(response.choices[0].message.content)
      setState({ ...state, response: response.choices[0].message.content });
      const u = new SpeechSynthesisUtterance(response.choices[0].message.content);          
      synth.speak(u);
    } catch (error) {
      console.error('Error al llamar al API de OpenAI', error);
    }
  }
  handleEnd2({steps})}, [state.question]);


  return (
    <div>
      <p>{state.response}</p>
    </div>
  );
}

const ActivareChatBot = () => {

    const steps = [
      
        {
          id: '1',
          message: '¡Hola! ¿En qué puedo ayudarte?',
          trigger: '2',
        },
        {
          id: '2',
          user: true,
          trigger: 'search',
        },
        {
          id: 'search',
          component: <SearchIA />,
          asMessage: true,
          trigger: '2'
        }
      
    ];
      
    return <ThemeProvider theme={theme}>
      <ChatBot steps={steps} {...config}/>
      </ThemeProvider>
}
export default ActivareChatBot;